import React from 'react'
import '../styles/custome.css';
// import banner from '../img/1.jpg';
import banner from '../img/banner.jpg';

export const Banner = (obj) => {
  return (
    <div className='banner_start'>
        <img className="img_banner" src={banner} alt="banner" />
        {/* <span className='capa_banner'></span> */}
        <span className='title_banner'>{obj.title}</span>
    </div>
  );
}