import React, { useState } from 'react'
import { Link } from 'react-router-dom';

import '../styles/navbar.css';
import logo from '../img/Logo.png';

const scrollToBottom = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight, behavior: 'smooth'
  });
}

export const Navbar = () => {

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div>
      <header className='header_principal'>
        <img src={logo} alt="logo" className='img_logo'/>

        <div className="btn-hamburguesa">
          <button className="btn-hamburguesa_icono" onClick={toggleMenu}>☰</button>
        </div>

        <nav className={`nav_enlaces ${showMenu ? 'mostrar' : ''}`}>
          <ul>
            <li className='li_general li_inicio'>
              <Link to="/" className='a_general a_inicio'>Inicio</Link>

            </li>
            <li className='li_general li_portabilidad'>
              <Link to="portabilidad" className='a_general a_portabilidad'>Portabilidad</Link>
            </li>
            
            <li className='li_general li_compatibilidad'>
              <Link to="compatibilidad" className='a_general a_compatibilidad'>Compatibilidad</Link>
            </li>

            <li className='li_general li_contacto'>
              <Link onClick={scrollToBottom} className='a_general a_contacto'>Contacto</Link>
            </li>

            <li className='li_general li_recarga'>
              <Link to="https://recarga.internetparaelbienestar.mx/bienestar" className='a_recarga'>Recarga aquí</Link>
            </li>
          </ul>
        </nav>
      </header>
  </div>
  )
}
