import React from 'react'
import { Banner } from '../components/Banner'

export const Portabilidad = () => {
  return (
    <div>
      <Banner title={"Portabilidad"}/>
      <div className='info_container'>
        <div className='info_portability-container'>
          <div className='info_portability-title'>
            <span className='info_text'>Cámbiate a Internet para el Bienestar con tu mismo número y disfruta los mejores beneficios.</span>
            <br />
            <span className='info_subText'>¡Es muy sencillo en tan solo 5 pasos!</span>
          </div>

          <div className='info_portability-content'>

            <div className='info_portability_div'>
              <span className='phase_title'>Paso 1:</span>
              <div className='info_portability-subContent'>
                <div className='info_portability-text'>
                  <p>
                    <strong>Emvía un SMS</strong> al <strong>051</strong> con la <strong>palabra NIP</strong>, o marca al mismo número para obtener tu NIP de Portabilidad.
                    Deberás realizarlo desde tu número que tienes con tu compañía actual y que quieres conservar.
                    <br /><br />
                    <strong>Nota:</strong>
                    <br />
                    -El NIP tiene vigencia de 5 días naturales
                  </p>  
                </div>
                <span className='border_subContent'></span>
              </div>
            </div>

            <div className='info_portability_div'>
              <span className='phase_title'>Paso 2:</span>
              <div className='info_portability-subContent'>
                <div className='info_portability-text info_portability-alter'>
                  <p>
                    <strong>Adquiere una SIM de Internet para el Bienestar</strong>, o si ya la tienes
                    continua al paso 3
                  </p>
                </div>
                <span className='border_subContent info_portability-borderAlter'></span>
              </div>
            </div>

            <div className='info_portability_div'>
              <span className='phase_title'>Paso 3:</span>
              <div className='info_portability-subContent'>
                <div className='info_portability-text'>
                  <p>
                    <strong>Retira de tu celular la SIM </strong>con tu compañía actual e 
                    <strong>Ingresa en tu celular tu nueva SIM de Internet para el Bienestar.</strong>
                    <br />
                    <br />
                    Posteriormente descarga la App
                  </p>  
                </div>
                <span className='border_subContent'></span>
              </div>
            </div>

            <div className='info_portability_div'>
              <span className='phase_title'>Paso 4:</span>
              <div className='info_portability-subContent'>
                <div className='info_portability-text info_portability-alter'>
                  <p>
                    Abre la App de <strong>Internet para el Bienestar, </strong>
                    selecciona la opción de <strong>"porta tu número"</strong> y completa tus datos.
                  </p>
                </div>
                <span className='border_subContent info_portability-borderAlter'></span>
              </div>
            </div>

            <div className='info_portability_div'>
              <span className='phase_title'>Paso 5:</span>
              <div className='info_portability-subContent'>
                <div className='info_portability-text'>
                  <p>
                    <strong>Espera 48 hrs</strong>
                    <br /><br />
                    Y <strong>!Listo¡</strong> Dedícate a disfrutar de la mejor velocidad y beneficios
                  </p>  
                </div>
                <span className='border_subContent'></span>
              </div>
            </div>
          </div>

          <div className='info_portability-footer'>
            <p>
              !No compartas tu NIP con ninguna persona que no sea de Internet para el Bienestar!
            </p>
            <span>Recuerda que es personal e intrasferible</span>
          </div>
        </div>     
      </div>
    </div>
  )
}
