import React from 'react'
import { Carrusel } from './Carrusel' 
import { Link } from 'react-router-dom'

import '../styles/home.css'

import paquete1 from '../img/Paquetes-12.png'
import paquete2 from '../img/Paquetes-13.png'
import codigoBarra from '../img/CodigoBarra.jpg'
import puntos from '../img/tiendas.png'
import puntoVenta from '../img/punto-venta.png'
import www from '../img/www.png'
import logoGoogle from '../img/Logo-google.png'
import logoAppStore from '../img/Logo-AppStore.png'
import paginaWeb from '../img/pagina-web.png'

export const Home = () => {
  return (
    <>
      <Carrusel/> 
      <div className='mod-portabilidad'>
        <section className="color-fond_primario p-portabilidad">
            <h1 className="texto-uppercase color-principal">Portabilidad</h1>
            <div className="card-internet">
              <h1 className="texto-uppercase color-principal">¡Cámbiate a internet para el bienestar!</h1>
              <h2 className="texto-uppercase color-secundario">Y mantén tu número actual</h2>
          </div>
            <div className="section-flex margin-section">
              <div>
                <h2 className="texto-paquete color-principal texto-font400">Paque <span className="texto-paquete_span color-secundario">Spot 100</span></h2>
                <img src={paquete1} alt="paquete 1" className="img-planes"/>
                <h3 className="texto-politicaUso">*Aplica política de Uso Justo</h3>
                <button className="btn-compra bg-color-secundario color-white cursor-pointe">Compra AQUÍ</button>
              </div>
              <div>
                <h2 className="texto-paquete color-principal texto-font400">Paquete <span className="texto-paquete_span color-secundario">Spot 200</span></h2>
                <img src={paquete1} alt="paquete 2" className="img-planes"/>
                <h3 className="texto-politicaUso">*Aplica política de Uso Justo</h3>
                <button className="btn-compra bg-color-secundario color-white cursor-pointe">Compra AQUÍ</button>
              </div>
          </div>
          <p className="p-terminos">*Consulta Términos y Condiciones aquí Las velocidades comunicadas son Megas(Mbps) y/o Giga(Gbps) y corresponden a la velocidad máxima de trandferencia de datos de bajada ofrecida y alcanzable; la velocidad de baja y subida puede variar.</p>
          <div className="margin-beneficios">
            <h1 className="texto-beneficio texto-uppercase color-principal">¡Recibe beneficio <span className="color-secundario h1">durante 12 meses!</span></h1>
            <div className="card-consulta">
              <div className="grid-consulta_proceso">
                <div>
                  <h1 className="texto-consulta">Consulta el proceso para mantener tu número <span className="color-principal font-consulta">AQUÍ</span></h1>
                </div>
                <div className="pagina-web">
                  <img src={paginaWeb} alt="página web" className="img-pagina-web"/>
                </div>
                </div>
              </div>
          </div>
        </section>
      </div>

      <div className='mod-lineas'>
        <section>
          <div className="card-linea">
              <h1 className="texto-uppercase color-principal">O ¡compra tu línea y mantente conectado!</h1>
              <h2 className="texto-uppercase color-secundario texto-font800">con los paquetes que tenemos para ti</h2>
          </div>
        </section>

          <section className='section-flex_planes mt-paquetes'>

            <div className="section_planes">
              <h2 className="color-principal h2 texto-font400 m-0">Paque <span className="color-secundario h2">Spot 70</span></h2>
              <img src={paquete1} alt="paquete 1" className="img-section_planes"/>
              <h3 className="texto-politicaUso_paquete">*Aplica política de Uso Justo</h3>
              <button className="btn-compra_plan bg-color-secundario color-white cursor-pointe">Compra AQUÍ</button>
            </div>

            <div className="section_planes">
              <h2 className="color-principal h2 texto-font400 m-0">Paquete <span className="color-secundario h2">Spot 10D</span></h2>
              <img src={paquete1} alt="paquete 2" className="img-section_planes"/>
              <h3 className="texto-politicaUso_paquete">*Aplica política de Uso Justo</h3>
              <button className="btn-compra_plan bg-color-secundario color-white cursor-pointe">Compra AQUÍ</button>
            </div>

            <div className="section_planes">
              <h2 className="color-principal h2 texto-font400 m-0">Paque <span className="color-secundario h2">Spot 15D</span></h2>
              <img src={paquete1} alt="paquete 1" className="img-section_planes"/>
              <h3 className="texto-politicaUso_paquete">*Aplica política de Uso Justo</h3>
              <button className="btn-compra_plan bg-color-secundario color-white cursor-pointe">Compra AQUÍ</button>
            </div>

            <div className="section_planes">
              <h2 className="color-principal h2 texto-font400 m-0">Paquete <span className="color-secundario h2">Spot 20D</span></h2>
              <img src={paquete1} alt="paquete 2" className="img-section_planes"/>
              <h3 className="texto-politicaUso_paquete">*Aplica política de Uso Justo</h3>
              <button className="btn-compra_plan bg-color-secundario color-white cursor-pointe">Compra AQUÍ</button>
            </div>

            <div className="section_planes">
              <h2 className="color-principal h2 texto-font400 m-0">Paque <span className="color-secundario h2">Spot 200</span></h2>
              <img src={paquete1} alt="paquete 1" className="img-section_planes"/>
              <h3 className="texto-politicaUso_paquete">*Aplica política de Uso Justo</h3>
              <button className="btn-compra_plan bg-color-secundario color-white cursor-pointe">Compra AQUÍ</button>
            </div>

        </section>
      </div>

      <section className="mod-compatible p-5 color-fond_primario">
         <div className='section-flex_compatible'>
          <div className="w-54">
            <h1 className="texto-compatible color-secundario">¡Comprueba que tu celular es compatible!</h1>
            <p className="text-left texto-compatible_p">
              Para que puedas disfrutar la máxima velocidad de navegación
              y calidad en llamadas asegúrate que tu celular es compatible con nuestra red.
            </p>
            <p className="text-left texto-qr_p">
              ¡Escanea el QR y sigue los pasos!
            </p>
          </div>
          <div className="w-67 w-56">
            <img src={codigoBarra} alt="Código de Barra" className="img-barra"/>
          </div>
         </div>
      </section>

      <section className="mod-punto_venta">
        <div className="card-sim">
            <h1 className="texto-uppercase color-principal">¡Adquiere tu sim en cualquiera de nuestro puntos de venta!</h1>
        </div>
        <div className="tarjetas">
          <div className="tarjeta-border">
            <div className="tarjeta-color">
              <img src={puntos} alt="paquete 2" className="img-planes"/>
            </div>
            <div>
              <p>Colocar dirección de algún punto de venta aquí.</p>
            </div>
          </div>

          <div className="tarjeta-border">
            <div className="tarjeta-color">
              <img src={puntos} alt="paquete 2" className="img-planes"/>
            </div>
            <div>
               <p>Colocar dirección de algún punto de venta aquí.</p>
            </div>
          </div>

          <div className="tarjeta-border">
            <div className="tarjeta-color">
              <img src={puntos} alt="paquete 2" className="img-planes"/>
            </div>
            <div>
               <p>Colocar dirección de algún punto de venta aquí.</p>
            </div>
          </div>
        </div> 
      </section>

      <section className="mod-recargas color-fond_primario pt-2">
        <div className="card-recargas">
          <h1 className='pt-recarga texto-uppercase color-principal'>¡Recarga tu línea sin preocupaciones!</h1>
          <h3 className="pb-recarga texto-uppercase color-secundario">Hazlo fácil y rápido en los siguientes sitios:</h3>
        </div>
        <div className="section-flex_recarga">
            <div className="card-recarga_puntos">
              <h2 className="texto-uppercase color-terciario">Recarga en línea</h2>
              <div className="recarga_puntos_flex">
                <img src={www} alt="www" className="img-punto_recarga"/>
                <Link to={"https://recarga.internetparaelbienestar.mx/bienestar"} target="_blank" className="btn-recarga_punto texto-uppercase bg-color-principal color-white cursor-pointe">
                  Aqui
                </Link>
                {/* <button className="btn-recarga_punto texto-uppercase bg-color-principal color-white cursor-pointe">Aquí</button> */}
              </div>
            </div>
            <div className="card-recarga_puntos">
              <h2 className="texto-uppercase color-terciario">Consulta puntos de recarga</h2>
              <div className="recarga_puntos_flex">
                <img src={puntoVenta} alt="Punto de venta" className="img-punto_venta"/>
                <button className="btn-punto_venta texto-uppercase bg-color-principal color-white cursor-pointe">Aquí</button>
              </div>
            </div>
          </div>
      </section>

      <section className="mod-app">
        <div className="card-app">
          <h1 className="texto-uppercase">Obtén el app internet para el bienestar <span className="color-secundario h1">y ten el cotrol de tu número</span></h1>
        </div>
        <div className="text-app">
          <h2 className="texto-app_saldo">Consulta tu saldo, realiza recargas de paquetes, ¡y mucho más!</h2>
          <h2 className="texto-app_saldo">Descarga la app AQUÍ:</h2>
        </div>
        <div className="app-flex">
          <Link to="https://play.google.com/store/apps/details?id=com.koonolmexico.client.klangg" className="link-android" target="_blank">
            <img src={logoGoogle} alt="Punto de venta" className="img-google"/>
          </Link>
          <Link to="https://apps.apple.com/mx/app/internet-para-el-bienestar/id1672404393" className="link-apple" target="_blank">
            <img src={logoAppStore} alt="Punto de venta" className="img-appStore"/>
          </Link>
        </div>
      </section>
      
    </>
  )
}
