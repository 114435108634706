import React, { useState } from 'react'
import '../styles/compatibilidad.css';
import Loader from "react-js-loader";
import { getImei } from '../api/getImei';
import { Banner } from '../components/Banner';

export const Compatibilidad = () => {

  const [imei, setImei] = useState('')
  const [show, setShow] = useState(false)
  const [loader, setLoader] = useState(false)
  const [eSIM, setESIM] = useState('')
  const [homologa, setHomologa] = useState('')
  const [subcategory, setSubcategory] = useState('')

  const consultImei = async () => {
    setLoader(true)
    const data = await getImei(imei)
    setSubcategory(data.imei.sub_category)
    setHomologa(data.imei.homologated)
    setESIM(data.imei.soportaESIM)
    setLoader(false)
    setShow(true)
  }
  return (
    <>
      <Banner title={'Compatibilidad'}></Banner>
      <div className="card-compatibilidad">
        <div className="title-compatibilidad">
          <h1>Compatibilidad</h1>
        </div>

        <div className="sub-title">
          <p>Es importante que valides que tu celular es compatible con nuestra red para que puedas disfrutar <br/> en su totalidad de los beneficios que te ofrecemos <br/><span>¡Compruebalo aquí!</span></p>
        </div>

        <div className="body-compartibilidad">
          <div className="">
            <p>Ingrese el número de <span>IMEI</span></p>
          </div>
          <div className="">
            <input  name="imei" maxLength={16}  className='inputImei' value={imei} onChange={(event)=>setImei(event.target.value)}/>
          </div>

          <div className="">
            <button
              onClick={()=>consultImei()}
              className='btn-imei'
              accessibilityLabel='Consultar'
            >CONSULTAR</button>
          </div>

          {
            loader &&(
              <Loader type={'spinner-default'} size={70} color={'black'} bgColor={'red'}/>
            )
          }
          

          {
            show ?
              <div className='text-imei'>
                <p className='title-response'>El equipo es compatible con la red.</p>
                <p className='p-response'>Equipo: {homologa}</p> 
                <p className='p-response'>Uso: {subcategory}</p>
                <p className='p-response'>eSIM: {eSIM}</p>
              </div> : <div className=""></div>
          }
        </div>

        <div className="foote-text">
          <p>El <span>IMEI</span> es el código internacional de identidad que tiene cada teléfono celular y <br/>que lo distingue de manera única.</p>
        </div>
        <div className="foote-text">
          <p>Para conocer tu <span>IMEI</span> debes marcar <span>*#06#</span> en el dispositivo que utilizarás en nuestra red.</p>
        </div>
      </div>
    </>
  )
}