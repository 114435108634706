import React, { useEffect, useState } from 'react';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import imagen1 from '../img/1.jpg';
import imagen2 from '../img/2.jpg';
import imagen3 from '../img/3.jpg';

import '../styles/carousel.css';

const images = [imagen1, imagen2, imagen3];

export const Carrusel = () => {

  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
  };

    // const [currentImagen, setCurrenImagen] = useState(0);
    // const images = [imagen1, imagen2, imagen3];
  
    // useEffect(() => {
    //   const interval = setInterval(() => {
    //     setCurrenImagen((prevImagen) => (prevImagen + 1) % images.length);
    //   }, 5000);
  
    //   return () => clearInterval(interval);
    // }, []);

  return (
    <>
     <Slider {...settings}>
      <div>
        <img src={imagen1} alt="Image 1" />
      </div>
      <div>
        <img src={imagen2} alt="Image 2" />
      </div>
      <div>
        <img src={imagen3} alt="Image 3" />
      </div>
    </Slider>
      {/* <div className='carousel'>
        {images.map((image, index) => (
          <img 
            key={index}
            src={image}
            alt={`Imagen ${index + 1}`}
            className={index === currentImagen ? 'active' : ''}
          />
        ))}
      </div>
      <div>
        <p>kamaklsklasdnxcalvryyhyrhyhyytyyyjhty</p>
      </div> */}
    </>
  )
}
