import React from 'react'
import '../styles/footer.css'

import logo from '../img/Logo.png'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <>
      <footer className="footer">

        <div className="footer-content">

          <div className="footer-left">
              <img src={logo} alt="logo" className="imagen-front" />
          </div>

          <div className="footer-right">
            
            <div className='footer-right-content'>

              <div className='consulta'>
                <h2>Consulta</h2>
                <Link to="cobertura" className="link-cobertura">
                  <h3 className='footer-ul'>Cobertura</h3>
                </Link>

                <Link to={'preguntas'}>
                  <h3 className='footer-ul'>Preguntas Frecuentes</h3>
                </Link>
              </div>

              <div className='legales'>
                <h2>Legales</h2>
                  <h3 className='text-section'>Aviso de privacidad</h3>
                  <h3 className='text-section'>Terminos y condiciones de planes</h3>
                  <h3 className='text-section'>Política de Uso Justo</h3>
                  <h3 className='text-section'>Contrato de Adhesión con Profeco</h3>
                  <h3 className='text-section'>Carta de derechos mínimos de usuarios</h3>
                  <h3 className='text-section'>Buscador de Tarifas IFT</h3>
                  <h3 className='text-section'>Código de gestión de tráfico de la red</h3>
                  <h3 className='text-section'>Área de colaboración con la justicia</h3>
                  <h3 className='text-section'>Formatos simplificados</h3>
              </div>

              <div className='contacto'>
                <h2 className="color-principal texto-uppercase font-800">Contacto</h2>
                  <p className="p-contacto"> 
                    Si quieres contactarnos llama al *444 desde tu celular o al 55 93311394
                    desde cualquier teléfono en un horario de 09:00 a 21:00 hrs de lunes a domingo.
                  </p>
              </div> 
              
            </div>
          </div> 
        </div>
  
      </footer>
        <div className='barra-footer'>
        <p>
            &copy; 2023 Móvil para todos. Todos los derechos reservados.
        </p>
        </div> 
    </>
  )
}
