import React from 'react'
import { Route, Routes, Router } from 'react-router'
import { Navbar } from '../components/Navbar'
import { Home } from '../views/Home'
import { Portabilidad } from '../views/Portabilidad'
import { Compatibilidad } from '../views/Compatibilidad'
import { Recarga } from '../views/Recarga'
import { Question } from '../views/Question'
import { Footer } from '../components/Footer'
import { Cobertura } from '../views/Cobertura'

export const AppRouter = () => {
  return (
    <>
      <Navbar />
      <Routes>
      
          <Route path="/" 
            element={
              <Home  />
            }
          /> 

          <Route path="/portabilidad" 
            element={
              <Portabilidad  />
            }
          /> 

          <Route path="/compatibilidad" 
            element={
              <Compatibilidad  />
            }
          /> 

          <Route path="/recarga" 
            element={
              <Recarga  />
            }
          /> 

          <Route path="/preguntas" 
            element={
              <Question  />
            }
          /> 

          <Route path="/cobertura" 
            element={
              <Cobertura  />
            }
          /> 
      </Routes>
      <Footer />
    </>
  )
}
