import React from 'react'
import '../styles/custome.css';
import { Banner } from '../components/Banner'

export const Question =  () => {

    const showAnswer = async (container, answer) => {
        container = document.getElementById(container);
        answer = document.getElementById(answer);
        await hideAnswer();
        container.classList.add("quest_content_active");
        answer.classList.add("answer_active");
    }

    function hideAnswer(){
        let containers = document.getElementsByClassName("quest_content_active");
        let answers = document.getElementsByClassName("answer_active");

        for (const container of containers) {
            container.classList.remove("quest_content_active");
        }
        for (const answer of answers) {
            answer.classList.remove("answer_active");
        }
    }

    return (
    <div>
        <Banner title={"PREGUNTAS FRECUENTES"}/>

        <div className='info_container'>
            <div id="qc1" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc1", "qa1")}>¿CÓMO SÉ SI MI CELULAR ES COMPATIBLE?</button>
                <p id="qa1" className='answer '>
                    Ingresa a la sección de (CTA “Compatibilidad”) en nuestro sitio web, y sigue los pasos.
                </p>
            </div>
            <div id="qc2" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc2", "qa2")}>¿QUÉ ES EL IMEI?</button>
                <p id="qa2" className='answer '>
                    El IMEI es el código internacional de identidad que tiene cada teléfono celular y que lo distingue de manera única. 
                    Puedes conocer tu IMEI marcando desde tu teléfono *#06#
                </p>
            </div>
            <div id="qc3" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc3", "qa3")}>¿INTERNET PARA EL BIENESTAR CUENTA CON APLICACIÓN?</button>
                <p id="qa3" className='answer '>
                    ¡Sí! Puedes descargarla en el sitio web, en (CTA “Descarga la App”) 
                </p>
            </div>
            <div id="qc4" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc4", "qa4")}>¿QUÉ PUEDO HACER EN LA APLICACIÓN DE INTERNET PARA EL BIENESTAR?</button>
                <p id="qa4" className='answer '>
                    La App Internet para el Bienestar es la forma más sencilla de tener el control de tus servicios Internet para el Bienestar. 
                    Puedes consultar tu saldo y vigencia, realizar el proceso de portabilidad de tu número, realizar recargas de telefonía móvil.   
                </p>
            </div>
            <div id="qc5" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc5", "qa5")}>¿QUÉ PASA SI NO RECUERDO LA CONTRASEÑA DE LA APP INTERNET PARA EL BIENESTAR?</button>
                <p id="qa5" className='answer '>
                    Puedes recuperar tu contraseña en la opción “Olvidé mi contraseña” en la página de registro de la App, sigue las instrucciones que te llegarán por correo.
                </p>
            </div>
            <div id="qc6" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc6", "qa6")}>¿CÓMO SÉ CUÁL ES MI NÚMERO CELULAR? </button>
                <p id="qa6" className='answer '>
                    Cuando insertes tu SIM Internet para el Bienestar en el teléfono, recibirás un mensaje de texto de bienvenida, dónde se indica tu número celular.
                </p>
            </div>
            <div id="qc7" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc7", "qa7")}>¿CÓMO PUEDO CONSULTAR MI SALDO?</button>
                <p id="qa7" className='answer '>
                    Puedes consultar tu saldo de las siguientes 3 maneras:
                    <li>1. Descarga la App Internet para el Bienestar, registra tu número celular y consulta tu saldo.</li>
                    <li>2. Envía un SMS con la palabra SALDO al 52142. Te llegará un SMS instantáneo con tu saldo y la fecha de vigencia.</li>
                    <li>3. Llama a nuestro centro de atención telefónica, marcando *444 desde tu línea Internet para el Bienestar, posteriormente selecciona la opción “Consulta de saldo”</li>
                </p>
            </div>
            <div id="qc8" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc8", "qa8")}>¿QUÉ PASA SI SE ME ACABAN LOS DATOS?</button>
                <p id="qa8" className='answer '>
                    En caso de agotar tus datos, se tendría que realizar una recarga descargando la App Internet para el Bienestar. También puedes consultar los diferentes puntos de recarga físicos en nuestra página de inicio.
                </p>
            </div>
            <div id="qc9" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc9", "qa9")}>¿EN DONDE PUEDO REALIZAR MIS RECARGAS?</button>
                <p id="qa9" className='answer'>
                    Tenemos las siguientes modalidades de recarga para Internet para el Bienestar:
                    <li>1. Recarga en línea AQUÍ</li>
                    <li>2. App Internet para el Bienestar Descarga AQUÍ</li>
                    <li>3. Puntos de recarga como: Farmacias del Ahorro, etc.</li>
                    <li>4. El punto de venta en donde compraste tu SIM</li>
                </p>
            </div>
            <div id="qc10" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc10", "qa10")}>¿QUÉ OCURRE DESPUÉS DE QUE REALIZO MI RECARGA?</button>
                <p id="qa10" className='answer '>
                    Para confirmar que tu recarga fue exitosa, te llegará un SMS de confirmación con los beneficios asignados y fecha de vigencia.
                    Recuerda que tu saldo y la fecha de vigencia, corresponden al monto de la recarga realizada.

                </p>
            </div>
            <div id="qc11" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc11", "qa11")}>¿PUEDO CONSERVAR EL NÚMERO QUE TENÍA CON OTRA COMPAÑÍA?</button>
                <p id="qa11" className='answer '>
                    Sí. La portabilidad es un derecho que como consumidor te permite elegir la compañía telefónica que consideres más conveniente, SIN PERDER o cambiar tu número telefónico actual.
                </p>
            </div>
            <div id="qc12" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc12", "qa12")}>¿CÓMO PUEDO HACER LA PORTABILIDAD?</button>
                <p id="qa12" className='answer '>
                    Puedes realizar tu portabilidad directamente en los puntos de venta al adquirir tu SIM Internet para el Bienestar, o mediante la App Internet para el Bienestar una vez que adquiriste tu SIM.
                    Si tienes algún problema durante el proceso o quieres que alguien te acompañe a realizarlo, llama a nuestro centro de atención telefónica al 55 9331 1394.
                </p>
            </div>
            <div id="qc13" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc13", "qa13")}>¿QUÉ HAGO SI AL SOLICITAR MI NIP DE PORTABILIDAD ME GENERA UN ERROR?</button>
                <p id="qa13" className='answer '>
                    En caso de presentar algún error al solicitar tu NIP, recuerda que puedes volver a solicitarlo por el mismo medio.
                </p>
            </div>

            <div id="qc14" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc14", "qa14")}>¿CUÁNTO TIEMPO TARDA EL PROCESO?</button>
                <p id="qa14" className='answer '>
                    El proceso de portabilidad tarda entre 24 y 48 hrs hábiles y el cambio se hará en automático.
                </p>
            </div>
            <div id="qc15" className='quest_content '>
                <button className='quest' onClick={()=>showAnswer("qc15", "qa15")}>¿ME QUEDARÉ SIN SERVICIO EN EL PROCESO?</button>
                <p id="qa15" className='answer '>
                    ¡No te preocupes! Mientras hacemos tu cambio puedes seguir usando tu número de siempre con tu SIM anterior.
                </p>
            </div>
        </div>
    </div>
    )
}
