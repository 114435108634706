import React from 'react'
import { MapContainer, TileLayer, WMSTileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import '../styles/cobertura.css'

export const Mapa = () => {

  return (
    <MapContainer center={[23.0000000,-102.0000000]} zoom={6}  className="mapCobertura">
    <TileLayer
      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    />
    <WMSTileLayer 
        url="https://geomap.altanredes.com/geoserver/web_altanredes_geoaltan/ows?SERVICE=WMS?&authkey=781c7a49"
        layers="Cobertura_Actual"
        format="image/png"
        transparent={true}
        version="1.3.0"
    />
  </MapContainer>
  )
}

