import React from 'react'
import { Banner } from '../components/Banner'
import { Mapa } from '../components/Mapa'

import '../styles/cobertura.css'

export const Cobertura = () => {
  return (
    <>
        <Banner title={"COBERTURA"}/>
        <div className="cobertura-border">
            <div className="cobertura-texto">
                <h2>Consulta nuestro mapa de cobertura aquí:</h2>
            </div>
            <div className="cobertura-img">
                <Mapa />
            </div>
        </div>
    </>
  )
}

